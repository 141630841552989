import { createActionGroup, props } from '@ngrx/store';
import { Enrollment } from 'src/app/shared/models';

export const EnrollmentActions = createActionGroup({
  source: 'Enrollment',
  events: {
    'Load Enrollments': props<{ consumerId: string }>(),
    'Load Enrollments Success': props<{ enrollments: Enrollment[] }>(),
    'Load Enrollments Failure': props<{ error: any }>(),
    'Load Enrollment': props<{ enrollmentId: string; consumerId: string }>(),
    'Load Enrollment Success': props<{ enrollment: Enrollment }>(),
    'Load Enrollment Failure': props<{ error: any }>(),
    'Add Enrollment': props<{
      consumerId: string;
      enrollment: Enrollment | Partial<Enrollment>;
    }>(),
    'Add Enrollment Success': props<{ enrollment: Enrollment }>(),
    'Add Enrollment Failure': props<{ error: any }>(),
    'Remove Enrollment': props<{ enrollmentId: string; consumerId: string }>(),
    'Remove Enrollment Success': props<{ enrollmentId: string }>(),
    'Remove Enrollment Failure': props<{ error: any }>(),
    'Update Enrollment': props<{
      consumerId: string;
      enrollmentId: string;
      enrollment: Partial<Enrollment>;
    }>(),
    'Update Enrollment Success': props<{
      consumerId: string;
      enrollmentId: string;
      enrollment: Partial<Enrollment>;
    }>(),
    'Update Enrollment Failure': props<{ error: any }>(),
  },
});
